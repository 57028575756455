import * as $ from "jquery";

export function initFooter() {
    const $footer = $('footer');
    if ($footer.length) {
        console.log('found footer');
        window.addEventListener('resize', () => window.requestAnimationFrame(updateFooter));
        updateFooter();
    }
}

function updateFooter() {
    const $footer = $('footer');
    const $body = $('body');
    const documentHeight = $(document).height();
    const windowHeight = $(window).height();
    const footerHeight = $footer.height();

    console.log('updating footer', { documentHeight, windowHeight, footerHeight });
    if (documentHeight && windowHeight && footerHeight && documentHeight <= windowHeight) {
        $footer.css({
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
        });
        $body.css({
            'margin-bottom': footerHeight,
        });
    }
    else {
        $footer.css({
            position: 'static',
        });
        $body.css({
            'margin-bottom': 0,
        });
    }
}