import * as $ from 'jquery';

export function initTables() {
    //
    // this could be the place to manipulate tables
    //
    $(".clickable-row").on('click', function() {
        const href = $(this).attr("href");
        if (href) {
            window.location.href = href;
        }
    });
    $("a.page-link").each(function() {
        const oldUrl = $(this).attr("href"); // Get current url
        if (oldUrl) {
            // Create new url
            const newUrl = oldUrl.replace(":%20ASC", ",ASC")
                .replace(":%20DESC", ",DESC");

            // Set new url
            $(this).attr("href", newUrl);
        }
    })
}
