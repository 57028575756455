import {Stomp} from "@stomp/stompjs";

enum LockStatus {
    Open = 'Open',
    Closed = 'Closed',
    Defective = 'Defective',
    Breakin = 'Breakin',
    Blocked = 'Blocked'
}

interface LockStateChangeEvent {
    boxId: number;
    lockHandle: string;
    lockStatus: LockStatus;
}

export function initLockStateChange() {

    const $lockStateContainers = $('[data-lock-status]');

    if ($lockStateContainers.length > 0) {

        const webSocketClient = Stomp.client('ws://localhost:5000/lock-events');
        const headers = {
            // additional header
            'client-id': 'my-client-id'
        };

        webSocketClient.connect(headers, () => {
            $lockStateContainers.each(function () {
                const $lockStatusContainer = $(this);
                const handle = $(this).data('lock-status') as string;

                webSocketClient.subscribe(`/locks/${handle}`, message => {
                    const lockStateChangeEvent = JSON.parse(message.body) as LockStateChangeEvent;
                    $lockStatusContainer.text(lockStateChangeEvent.lockStatus);
                })
            });
        });

    }

}