import * as $ from "jquery";
import {invokeGoogleReCAPTCHA} from "./form";

export function initRaiffeisenRegistrationFrom() {
    const $form = $('#raiffeisenbankGrammastettenHerzogsdorfRegistrationForm');
    if ($form.length) {
        $('.selectPlan').click(function () {
            console.log('Clicked button with class selectPlan');
            var btn = $(this);
            var planName = btn.data('plan-name');
            $('#plan').val(planName);
        });
        showRaiffeisenPanel('account');
        $('#iban').on('input', function (e: Event) {
            checkRaiffeisenRoutingCode();
        });
        $('#account-next').click(function () {
            showRaiffeisenPanel('user');
            return false;
        });
        $('#user-prev').click(function () {
            showRaiffeisenPanel('account');
            return false;
        });
        $('#user-next').click(function () {
            showRaiffeisenPanel('tarif');
            return false;
        });
        $('#plan-prev').click(function () {
            showRaiffeisenPanel('user');
            return false;
        });
        $('#plan-next').click(function () {
            showRaiffeisenPanel('summary');
            return false;
        });
        $('#summary-prev').click(function () {
            showRaiffeisenPanel('tarif');
            return false;
        });
        if ($('#termsCheckbox').length > 0) {
            $('#submitForm').prop('disabled', true);
            $('#termsCheckbox').change(function () {
                updateSubmitDisabledProperty();
            });
            $('#sendSignatureReleaseForms').change(function () {
                updateSubmitDisabledProperty();
            });
        }
        updateSubmitDisabledProperty();
        let captchaWidget: any = undefined;
        $form.on('submit', function(e) {
            if (captchaWidget === undefined) {
                e.preventDefault();
                captchaWidget = invokeGoogleReCAPTCHA($form, $('#submitForm').get()[0]);
            }
        });
    }
}

function updateSubmitDisabledProperty() {
    let termsChecked = $('#termsCheckbox').is(':checked');
    let releaseFormsChecked = $('#sendSignatureReleaseForms').is(':checked');
    $('#submitForm').prop('disabled', !termsChecked || !releaseFormsChecked);
}

function showRaiffeisenPanel(panelId: string) {
    $('.raiffeisen-panel').hide();
    $('#' + panelId).show();
    $('#progress-' + panelId).addClass('active');
    computeSummary();
}

function computeSummary() {
    summarizeInput('title');
    summarizeInput('firstName');
    summarizeInput('lastName');
    summarizeInput('companyName');

    summarizeInput('addressLine1');
    summarizeInput('addressLine2');
    summarizeInput('zipCode');
    summarizeInput('city');
    summarizeInput('country');

    summarizeInput('email');
    summarizeInput('phone');

    summarizeInput('iban');
}

function summarizeInput(inputId: string) {
    assignSummary('#summary-' + inputId, '#' + inputId);
}

function assignSummary(summaryId: string, inputId: string) {
    $(summaryId).html($(inputId).val() ? <string>$(inputId).val() : '');
}

export interface StructuredIban {
    countryCode: string,
    checkSum: string,
    routingCode: string,
    accountNumber: string
}

export function toStructuredIban(iban: string): StructuredIban {
    iban = iban.replace(/\s/g, "");
    return {
        countryCode: iban.substring(0, 2),
        checkSum: iban.substring(2, 4),
        routingCode: iban.substring(4, 9),
        accountNumber: iban.substring(9)
    };
}

export function ibanHasRoutingCode(iban: string, routingCode: string): boolean {
    let structuredIban = toStructuredIban(iban);
    return structuredIban.routingCode == routingCode;
}

export function checkRaiffeisenRoutingCode() {
    console.log('Checking input "iban" for a Raiffeisen routing code');
    let iban = $('#iban').val() as string;
    if (ibanHasRoutingCode(iban, '34135')) {
        $('#btn-raiffeisen-kunde').removeClass('disabled');
        $('#plan').val($('#btn-raiffeisen-kunde').data('plan-name'));
        $('#raiffeisen-kunde-check').addClass('green');
        $('#raiffeisen-kunde-check').removeClass('grey');
        $('#nicht-raiffeisen-kunde-check').addClass('grey');
        $('#nicht-raiffeisen-kunde-check').removeClass('green');
        $('#btn-raiffeisen-kunde').data('plan-name');
        $('#summary-plan').html('Kostenlos für Kunden der Raiffeisenbank Gramastetten Herzogsdorf.');
    } else {
        console.log($('#btn-nicht-raiffeisen-kunde').data('plan-name'));
        $('#btn-raiffeisen-kunde').addClass('disabled');
        $('#plan').val($('#btn-nicht-raiffeisen-kunde').data('plan-name'));
        $('#raiffeisen-kunde-check').addClass('grey');
        $('#raiffeisen-kunde-check').removeClass('green');
        $('#nicht-raiffeisen-kunde-check').addClass('green');
        $('#nicht-raiffeisen-kunde-check').removeClass('grey');
        $('#summary-plan').html('Tarif für die öffentliche Nutzung (kein Kunde der Raiffeisenbank Gramastetten Herzogsdorf) €&nbsp;6,95 per Monat.');
    }
}