import * as $ from "jquery";
import * as moment from "moment";
import "bootstrap";
import "tempusdominus-bootstrap-4";

import "../scss/main.scss";

import {initTables} from "./tables";
import {initTenantUser} from "./tenant";
import {initLockStateChange} from "./lock";
import {initFormValidation, initLastUsedCubeSelect, initDeleteOccupancyForm} from "./form";
import {initBackButton} from "./back";
import {initRaiffeisenRegistrationFrom} from "./registrationRaiffeisen";
import {initDefaultRegistrationForm} from "./registrationDefault";
import {initFooter} from "./footer";
import {initTenantCustomization} from "./tenant-customization";

(window as any)["$"] = (window as any)["jQuery"] = $;

// when DOM is ready
$(() => {
    console.log('DOM is ready');

    // initialize datetimepicker
    ($('.datetimepicker') as any).datetimepicker({
        format: 'DD/MM/YYYY HH:mm',
        extraFormats: [ moment.ISO_8601 ],
    });

    // tenant user
    initTenantUser();

    // tables
    initTables();

    // form validation
    initFormValidation();

    // install lock state change
    initLockStateChange();
    
    // init raiffeisen registration form
    initRaiffeisenRegistrationFrom();

    // init default registration form
    initDefaultRegistrationForm();

    // init selector of last used cube
    initLastUsedCubeSelect();
    
    // init behavior for delete occupancies
    initDeleteOccupancyForm();

    // init back button behavior
    initBackButton();

    // init footer
    initFooter();

    // init tenant specific
    initTenantCustomization();
});
