import * as $ from "jquery";

export function initTenantUser() {
    updateTenantUser();
    $('#role').change(function() {
        updateTenantUser();
    });
}

function updateTenantUser() {
    const role = $('#role');
    if (role.val() == 'TenantUser' || role.val() == 'TenantAdmin' || role.val() == 'TenantAPIUser') {
        $('#select-tenant').removeAttr('style');
        $('#tenant').prop('required', true);
    } else {
        $('#select-tenant').attr('style', 'display: none;');
        $('#tenant').removeAttr('required');
    }

    if (role.val() == 'ServicePartnerUser') {
        $('#select-service-partner').removeAttr('style');
        $('#service-partner').prop('required', true);
    } else {
        $('#select-service-partner').attr('style', 'display: none;');
        $('#service-partner').removeAttr('required');
    }
}
