import * as $ from "jquery";


export function initFormValidation() {
    // form validation
    $('form.needs-validation').on('submit', function(e:Event) {
        let form = this as HTMLFormElement;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        $(form).addClass('was-validated');
    });
}

export function initLastUsedCubeSelect() {
    $('#lastUsedCube').change(function() {
        $('#lastUsedCubeForm').submit();
    });
}

export function initDeleteOccupancyForm() {
    $('.delete-occupancy-button').click(function() {
        let href = $(this);
        let occupancyId = href.data('delete-occupancy-id');
        $('#deleteOccupancyForm #occupancyId').val(occupancyId);
    });
}

export function invokeGoogleReCAPTCHA(form: JQuery, containerElement: HTMLElement) {
    console.log('INVOKE Google reCAPTCHA');
    const recaptcha = (window as any)['grecaptcha'];
    window.setTimeout(() => $(containerElement).prop('disabled', true), 300);
    const onSubmit = (token: string) => {
        return new Promise(() => {
            console.log('formSubmitted: ', token);
            const $gToken = document.getElementById("g-token") as HTMLInputElement;
            if ($gToken) $gToken.value = token;
            form.trigger('submit');
        });
    };
    const onExpiredCallback = () => {
        $(containerElement).prop('disabled', false);
    };
    const onErrorCallback = (error: Error) => {
        console.error(error);
        $(containerElement).prop('disabled', false);
    };
    const captchaWidget = recaptcha.render(containerElement, {
        'sitekey' : '6Lcq788ZAAAAAIaCDMuM_Ov9IEqbIYIg0e2w8Bt8',
        'callback' : onSubmit,
        'expired-callback': onExpiredCallback,
        'error-callback': onErrorCallback
    });
    recaptcha.execute();
    return captchaWidget;
}
