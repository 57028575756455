import * as $ from "jquery";
import {invokeGoogleReCAPTCHA} from "./form";

export function initDefaultRegistrationForm() {
    const $defaultRegistrationContainer = $('.container.registration.default');
    if ($defaultRegistrationContainer.length) {
        initPasswordConfirmCheck();
        initCountry();
        initCubeRegistrationCode();

        const useMultiStep = location.search.indexOf("multiStep") >= 0 || $defaultRegistrationContainer.hasClass('multi-step');
        if (useMultiStep) {
            console.log('init multi step registration');
            initMultiStepRegistration($defaultRegistrationContainer);
        } else {
            console.log('init single step registration');
            initSingleStepRegistration($defaultRegistrationContainer);
        }
    }
}

function initCountry() {
    const element = $("#country");
    const defaultCountry = "AT";
    const navigatorCountry = navigator.language.slice(-2).toUpperCase();
    const options = element.children('option')
        .map((index, option) => $(option).attr("value"))
        .toArray();
    const country = options.indexOf(navigatorCountry) != -1 ? navigatorCountry : defaultCountry;
    element.children(`option[value=${country}]`).prop('selected', true);
    element.val(country);
}

function initCubeRegistrationCode() {
    const checkedOption = $("input:radio[name ='cubeId']:checked");
    if(checkedOption && checkedOption.length > 0 && checkedOption.data().hasRegistrationCode) {
        $('#registrationCodeBlock').show();
    }else{
        $('#registrationCodeBlock').hide();
    }
    $('.cubeSelector').on('change', (event) => {
        if($(event.target) && $(event.target).data().hasRegistrationCode) {
            $('#registrationCodeBlock').show();
        }else{
            $('#registrationCodeBlock').hide();
        }
    });
}

function initPasswordConfirmCheck() {
    const $password = $('#password');
    const $confirm = $('#confirm');

    const updateValidity = () => {
        const password = $password.val();
        const confirm = $confirm.val();
        const mismatch = password && confirm && password != confirm;
        console.log('updateValidity', { password, confirm, mismatch });
        $confirm.each(function() {
            (this as HTMLInputElement).setCustomValidity(mismatch ? "Das Passwort und die Bestätigung müssen übereinstimmen." : "");
        });
    };

    $password.on('change', updateValidity);
    $confirm.on('change', updateValidity);
}

function initMultiStepRegistration($registrationContainer:JQuery) {
    $registrationContainer.addClass("multi-step");

    //
    // prepare DOM
    //

    const $steps = $('.step');
    const $form = $registrationContainer.children('form');

    // prepend the progress indicator
    const $progress = $('<ul class="multi-step-progress"></ul>');
    $steps.each(function() {
        const stepName = $(this).data('step');
        const $step = $(`<li>${stepName}</li>`);
        $progress.append($step);
    });
    $registrationContainer.prepend($progress);

    // add back and next buttons to the #registration-nav
    const $registrationNav = $('#registration-nav');
    const captionBack = $registrationContainer.data('back-caption');
    const captionNext = $registrationContainer.data('next-caption');
    const $back = $(`<button type="button" class="btn btn-secondary">${captionBack}</button>`);
    const $submit = $('#submitForm');
    const captionSubmit = $submit.text();

    $registrationNav.prepend($back);

    //
    // compute summary
    //
    const $summary = $('#summary');
    $summary.removeAttr("style");
    $summary.show();
    $steps.each(function() {

        const $inputs = $(this).find('input,select');
        if ($inputs.length) {

            const $stepSummary = $('<div class="my-3"></div>');

            const stepName = $(this).data('step');
            $stepSummary.append(`<h5>${stepName}</h5>`);

            const $stepValueList = $('<dl class="row"></dl>');
            $stepSummary.append($stepValueList);

            $inputs.each(function() {
                if (this.id) {

                    const input = this as HTMLInputElement;

                    const label = $(`label[for=${this.id}]`).text();

                    if ($(this).attr('type') == 'checkbox') {
                        const $label = $(`<dt class="col-sm-12">${label}</dt>`);

                        const updateCheckbox = () => $label.toggle(input.checked);

                        $(this).on("change", updateCheckbox);
                        updateCheckbox();

                        $stepValueList.append($label);
                    }
                    else {
                        const $label = $(`<dt class="col-sm-3">${label}</dt>`);
                        const $value = $(`<dd class="col-sm-9"></dd>`);

                        const updateTextInput = () => {
                            const value = $(input).val();
                            if ($(input).attr('type') == 'password') {
                                $value.text('***');
                            }
                            else {
                                $value.text((value || '').toString());
                            }
                        };

                        $(this).on("change", updateTextInput);
                        updateTextInput();

                        $stepValueList.append($label);
                        $stepValueList.append($value);
                    }

                }
            });
            $summary.append($stepSummary);
        }
    });

    //
    // step navigation
    //
    let currentStep = 0;

    function showCurrentStep() {

        const $inactiveSteps = $steps.filter(index => index != currentStep);

        const isLastStep = currentStep + 1 == $steps.length;
        if (isLastStep) {
            $inactiveSteps.hide();
            $form.prepend($inactiveSteps);
        }
        else {
            $inactiveSteps.detach();
        }
        $form.prepend($steps.eq(currentStep));

        if (isLastStep) {
            $submit.trigger("focus");
        }
        else {
            $form.find('input').first().trigger("focus");
        }

        if (currentStep == 0) {
            $back.attr("disabled", "disabled");
        }
        else {
            $back.removeAttr("disabled");
        }

        $form.removeClass("was-validated");

        $submit.text(isLastStep ? captionSubmit : captionNext);

        $progress.children().each(function(index) {
            if (index < currentStep) {
                $(this).addClass("complete");
            }
            else if (index == currentStep) {
                $(this).removeClass("complete");
                $(this).addClass("active");
            }
            else {
                $(this).removeClass("complete");
                $(this).removeClass("active");
            }
        });
    }

    let captchaWidget: any = undefined;
    $form.on('submit', function(e) {
        if (currentStep + 1 < $steps.length) {
            // go to next step
            e.preventDefault();
            currentStep++;
            showCurrentStep();
        }
        else {
            // actually submit form
            if (captchaWidget === undefined) {
                e.preventDefault();
                captchaWidget = invokeGoogleReCAPTCHA($form, $submit.get()[0]);
            }
        }
    });

    $back.on("click", function() {
        currentStep--;
        showCurrentStep();
    });

    // show first step
    showCurrentStep();

}

function initSingleStepRegistration($registrationContainer:JQuery) {
    $('#summary').hide();
    const $form = $registrationContainer.children('form');
    const $submit = $('#submitForm');
    let captchaWidget: any = undefined;
    $form.on('submit', function(e) {
        if (captchaWidget === undefined) {
            e.preventDefault();
            captchaWidget = invokeGoogleReCAPTCHA($form, $submit.get()[0]);
        }
    });
}


